<template>
  <div
    class="promotion-card-wraper flex flex-wrap justify-center items-center relative mx- my-22 text-white"
    data-aos="zoom-in"
    :data-aos-delay="delay"
    data-aos-anchor-placement="top-bottom"
  >
    <img
      :src="require('./img/' + type + '.png')"
      class="absolute w-full h-full top-0 left-0 z-0 transform"
    />
    <div class="z-10 promotion-card-content w-6/10 h-4/10 overflow-hidden">
      <div class="promotion-card-title text-2xl">{{ title }}</div>
      <div class="promotion-card-description">{{ content }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PromotionCard",
  props: {
    title: {
      type: String,
      default: "title"
    },
    content: {
      type: String,
      default: "title"
    },
    type: {
      type: Number,
      default: 1
    },
    delay: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {}
};
</script>
<style >
.promotion-card-content {
}
</style>
