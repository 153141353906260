<template>
  <div
    class="page-container w-screen h-vh-9/10 max-vh-9/10 overflow-y-hidden relative flex flex-wrap justify-center"
  >
    <div class="container flex justify-center flex-wrap">
      <div
        class="flex flex-wrap w-screen justify-around items-center max-h-9/10-screen overflow-y-hidden"
      >
        <div class="flex flex-wrap w-full justify-center">
          <div
            v-for="(card, index) in cardList"
            :key="card.id"
            class="flex justify-center items-center w-full sm:w-6/12"
          >
            <promotion-card
              :title="card.title"
              :content="card.text_content"
              :type="card.card_type"
              :delay="index * 300 + 1000"
              class="promotion-card"
            >
            </promotion-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PromotionCard from "../components/PromotionCard/PromotionCard.vue";
export default {
  name: "PromotionsPage",
  data: function() {
    return {
      cardList: [
        {
          id: 1,
          title: "PROMOCIÓN 1",
          text_content:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam error inventore dignissimos maiores autem? Sapiente blanditiis impedit, laboriosam, officiis.",
          card_type: 1
        },
        {
          id: 2,
          title: "PROMOCIÓN 2",
          text_content:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam error inventore dignissimos maiores autem? Sapiente blanditiis impedit, laboriosam, officiis nesciunt aliquid accusantium dolore.",
          card_type: 2
        },
        {
          id: 3,
          title: "PROMOCIÓN 3",
          text_content:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam error inventore dignissimos maiores autem? Sapiente blanditiis impedit, laboriosam, officiis nesciunt aliquid accusantium dolore tenetur qui sunt vel consequuntur.",
          card_type: 3
        },
        {
          id: 4,
          title: "PROMOCIÓN 4",
          text_content:
            "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam error inventore dignissimos maiores autem? Sapiente blanditiis impedit, laboriosam.",
          card_type: 4
        }
      ]
    };
  },
  components: {
    PromotionCard: PromotionCard
  },
  computed: {},
  mounted() {
    this.$store.commit("updateBgApp", "");
  },
  destroyed() {},
  methods: {}
};
</script>
<style >
.promotion-card {
  width: 350px;
  height: 220px;
}

@media (min-width: 640px) {
  .promotion-card {
    width: 350px;
    height: 220px;
  }
}
@media (min-width: 768px) {

}
@media (min-width: 1024px) {
  .promotion-card {
    width: 425px;
    height: 266px;
  }
}
@media (min-width: 1280px) {
  .promotion-card {
    width: 460px;
    height: 288px;
  }
}
@media (min-width: 1536px) {
 .promotion-card {
    width: 510px;
    height: 320px;
  }
}
</style>
